@import url('https://fonts.googleapis.com/css2?family=Murecho:wght@600&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "Murecho", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: linear-gradient(
    112.1deg,
    rgba(32, 38, 57, 1) 11.4%,
    rgba(63, 76, 119, 1) 70.2%
  );
  display: flex;
  justify-content: center;

  @apply text-base;
  @apply text-[#D6D6D6];
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.content-section {
  @apply flex flex-col gap-6 lg:px-8 mb-20 transition-transform duration-300;
  transition-property: opacity, transform;
}

.mobile-heading {
  @apply lg:hidden sticky top-0 pl-2 py-2 z-10 backdrop-blur;
}

.navigation-link {
  @apply text-white p-4 hover:tracking-[0.2rem] transition-all origin-left hover:duration-300 hover:scale-110;
}

.project-div:hover > div > img {
  @apply lg:scale-150 lg:duration-300 rotate-0;
}

.experience-div:hover > .experience-period {
  @apply lg:duration-300 lg:scale-110 lg:bg-clip-text lg:text-transparent lg:bg-gradient-to-r lg:from-[#DBFE87] lg:to-violet-500;
}

.reveal-section {
  @apply max-md:translate-y-40 max-md:opacity-0;
}

.active-reveal-section {
  @apply opacity-100 translate-y-0;
}
